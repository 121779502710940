import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { useMemo } from "react";
import type { Address } from "../../data/address";
import type { Order } from "../../data/order";
import { type OrderItem, cases, totalCost, units } from "../../data/order-item";
import type { Store } from "../../data/store";
import type { Vendor } from "../../data/vendor";
import {
  fullCurrencyFormatter,
  preciseCurrencyFormatter,
} from "../../helpers/format-currency";

function orderTotal(orderItems: OrderItem[]) {
  return orderItems.reduce((total, orderItem) => {
    return total + totalCost(orderItem);
  }, 0);
}

function AddressDisplay({ name, address }: { name: string; address: Address }) {
  return (
    <div className="mt-2 mb-4 pl-2">
      <Line>{name} </Line>
      <Line>{address.line_one}</Line>
      <Line>{address.line_two}</Line>
      <Line>
        {address.city}, {address.state} {address.zipcode}
      </Line>
    </div>
  );
}

type LineProperties = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

function Line({ children, ...rest }: LineProperties) {
  if (!children) {
    return;
  }
  return <div {...rest}>{children}</div>;
}

type PurchaseOrderOptions = {
  store: Store;
  vendor: Vendor;
  order: Order;
  orderItems: OrderItem[];
};

export function PurchaseOrder({
  orderItems,
  store,
  vendor,
  order,
}: PurchaseOrderOptions) {
  const total = useMemo(() => {
    return fullCurrencyFormatter(orderTotal(orderItems));
  }, [orderItems]);

  return (
    <div className="text-xs">
      <h1 className="text-center uppercase font-semibold text-xl p-4 hidden print:block">
        Purchase Order
      </h1>

      <div className="grid grid-cols-3 p-4 pb-0">
        <div>
          {/* <!-- Column 1 --> */}
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white">
            Bill To
          </h2>
          <AddressDisplay name={store.name} address={store.billing_address} />
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white">
            Vendor
          </h2>
          <AddressDisplay name={vendor.name} address={vendor.address} />
        </div>
        <div>{/* <!-- Column 2 --> */}</div>
        <div>
          {/* <!-- Column 3 --> */}
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white">
            Order Date
          </h2>
          <div className="mt-2 mb-4 pr-2">
            <p className="text-right">
              {format(order.created_at, "MM/dd/yyyy")}
            </p>
          </div>
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white">
            Delivery Date
          </h2>
          <div className="mt-2 mb-4 pr-2">
            <p className="text-right">
              {format(
                addDays(order.submitted_at || order.created_at, 1),
                "MM/dd/yyyy",
              )}
            </p>
          </div>
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white">
            Ship To
          </h2>
          <div className="text-right pr-2">
            <AddressDisplay
              name={store.name}
              address={store.shipping_address}
            />
          </div>
        </div>
      </div>
      <div
        id="header"
        className="grid grid-cols-3 p-4 pb-0 divide-x border-green-200"
      >
        <div className="border-b">
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white grid place-content-center">
            Account / License #
          </h2>
          <div className="grid place-content-center">
            <p>{vendor.account_number}</p>
          </div>
        </div>
        <div className="border-b">
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white grid place-content-center">
            Terms
          </h2>
          <div className="grid place-content-center">
            <p>{vendor.terms}</p>
          </div>
        </div>
        <div className="border-b">
          <h2 className="pl-2 uppercase font-semibold bg-emerald-700 text-white grid place-content-center">
            Total
          </h2>
          <p className="pr-2 grid place-content-end">{total}</p>
        </div>
      </div>
      <div id="content" className="p-4">
        <table className="table-auto w-full border-collapse">
          <thead className="font-semibold bg-emerald-700 text-white uppercase text-center">
            <tr>
              <th className="px-1 border-b border-green-200" colSpan={10}>
                {store.name} –{" "}
                {format(
                  order.submitted_at || order.created_at,
                  "MM/dd/yyyy hh:mmaa",
                )}{" "}
                – ID # {order.id}
              </th>
            </tr>
            <tr>
              <th className="px-1 border-r border-green-200" rowSpan={2}>
                #
              </th>
              <th
                className="px-1 border-x border-green-200 max-w-36 text-clip"
                rowSpan={2}
              >
                Brand
              </th>
              <th className="px-1 border-x border-green-200" rowSpan={2}>
                Description
              </th>
              <th className="px-1 border-x border-green-200" rowSpan={2}>
                Size
              </th>
              <th className="px-1 border-x border-green-200" rowSpan={2}>
                Item #
              </th>
              <th
                className="px-1 border-x border-b border-green-200"
                colSpan={2}
              >
                Quantity
              </th>
              <th
                className="px-1 border-x border-b border-green-200"
                colSpan={2}
              >
                Cost
              </th>
              <th className="px-1 border-l border-green-200" rowSpan={2}>
                Extended
              </th>
            </tr>
            <tr>
              <th className="px-1 border-x border-green-200">Case</th>
              <th className="px-1 border-x border-green-200">Unit</th>
              <th className="px-1 border-x border-green-200">Case</th>
              <th className="px-1 border-x border-green-200">Unit</th>
            </tr>
          </thead>
          <tbody>
            {orderItems.map((orderItem, index) => (
              <tr
                key={orderItem.product.id}
                className={index % 2 === 1 ? "bg-slate-100" : ""}
              >
                <td className="px-1 border-r border-green-200">{index + 1}</td>
                <td className="px-1 border-x border-green-200 max-w-36 text-clip">
                  {orderItem.product.brand}
                </td>
                <td className="px-1 border-x border-green-200">
                  {orderItem.product.description}
                </td>
                <td className="px-1 border-x border-green-200">
                  {orderItem.product.size}
                </td>
                <td className="px-1 border-x border-green-200">
                  {orderItem.product.item_code}
                </td>
                <td className="px-1 border-x border-green-200 text-right">
                  {cases(orderItem) > 0 ? cases(orderItem) : ""}
                </td>
                <td className="px-1 border-x border-green-200 text-right">
                  {units(orderItem) > 0 ? units(orderItem) : ""}
                </td>
                <td className="px-1 border-x border-green-200 text-right">
                  {preciseCurrencyFormatter(
                    orderItem.product.case_qty * orderItem.product.last_cost,
                  )}
                </td>
                <td className="px-1 border-x border-green-200 text-right">
                  {preciseCurrencyFormatter(orderItem.product.last_cost)}
                </td>
                <td className="px-1 border-l border-green-200 text-right">
                  {fullCurrencyFormatter(totalCost(orderItem))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
