import { type FC, useCallback, useMemo } from "react";
import type { Store } from "../../data/store";
import { WidgetContainer } from "./widget-container";

import { BarPlot } from "@mui/x-charts/BarChart";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { LineHighlightPlot, LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import type { CurveType } from "@mui/x-charts/models";
import type { BarSeriesType, LineSeriesType } from "@mui/x-charts/models";
import type { SalesTotal } from "../../data/sales-total";

import { ChartsGrid } from "@mui/x-charts/ChartsGrid";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import type { SeriesValueFormatterContext } from "@mui/x-charts/models/seriesType/common";
import { shortCurrencyFormatter } from "../../helpers/format-currency";
import {
  MarginSummary,
  SalesSummary,
  TransactionsSummary,
} from "./sales-summaries";
import {
  thisYearColor,
  thisYearEstimatesColor,
} from "./sales-widget-constants";

const displayTypes = ["Sales", "Transactions", "Gross Margin"] as const;
type DisplayType = (typeof displayTypes)[number];

type SalesDisplayProperties = {
  store: Store;
  displayType: DisplayType;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const createSalesData = (totals: SalesTotal[], displayType: DisplayType) => {
  return totals.map((total) => {
    switch (displayType) {
      case "Sales": {
        return total.sales;
      }
      case "Transactions": {
        return total.transactions;
      }
      case "Gross Margin": {
        return total.sales - total.costs;
      }
    }
  });
};

export const StoreSalesStatsDisplay: FC<SalesDisplayProperties> = ({
  store,
  displayType,
}) => {
  const { historicalYears, thisYearSales } = useMemo(() => {
    const historical = store.sales_stats.historical;
    const thisYearSales = historical.at(-1);
    if (!thisYearSales) {
      throw "Missing this year sales data.";
    }

    // Get up to 4 previous years, excluding current year
    const historicalYears = historical.slice(-5, -1).reverse();

    return { historicalYears, thisYearSales };
  }, [store]);

  const valueFormatter = useCallback(
    (value: number | null) => {
      if (!value) return "N/A";

      if (displayType === "Transactions") {
        return value.toLocaleString("en-US");
      }

      return shortCurrencyFormatter(value);
    },
    [displayType],
  );

  const barSeries = useMemo(() => {
    const thisYearData = createSalesData(thisYearSales.monthly, displayType);
    const thisYearEstimatesData = createSalesData(
      store.sales_stats.current_year_estimates.monthly,
      displayType,
    ).map((value, index) => {
      // For the current month, deduct the actual value from the estimates to make the bar stacking work correctly
      if (index === new Date().getMonth()) {
        return value - thisYearData[index];
      }

      return value;
    });

    return [
      {
        type: "bar" as const,
        label: `${thisYearSales?.year}`,
        data: thisYearData,
        id: "thisYear",
        color: thisYearColor,
        valueFormatter,
        stack: "currentYear",
      },
      {
        type: "bar" as const,
        label: `${thisYearSales?.year} Est.`,
        data: thisYearEstimatesData,
        id: "thisYearEstimates",
        color: thisYearEstimatesColor,
        valueFormatter: (
          value: number | null,
          { dataIndex }: SeriesValueFormatterContext,
        ) => {
          // For the current month, re-add the current value to the estimates to make the tooltip content correct
          if (dataIndex === new Date().getMonth()) {
            return valueFormatter((value || 0) + thisYearData[dataIndex]);
          }

          return valueFormatter(value);
        },
        stack: "currentYear",
      },
    ] satisfies BarSeriesType[];
  }, [store, thisYearSales, displayType, valueFormatter]);

  const historicalSeries = useMemo(() => {
    return historicalYears.map((yearData, index) => {
      const opacity = 1.0 - index * 0.2;
      return {
        type: "line" as const,
        label: `${yearData.year}`,
        data: createSalesData(yearData.monthly, displayType),
        id: `year${yearData.year}`,
        curve: "linear" as CurveType,
        color: `rgba(59, 130, 246, ${opacity})`,
        showMark: true,
        valueFormatter,
      };
    }) satisfies LineSeriesType[];
  }, [historicalYears, displayType, valueFormatter]);

  const maxValue = useMemo(() => {
    const barMax = Math.max(...barSeries.flatMap((s) => s.data));
    const lineMax = Math.max(...historicalSeries.flatMap((s) => s.data));
    return Math.ceil(Math.max(barMax, lineMax) * 1.1);
  }, [barSeries, historicalSeries]);

  return (
    <WidgetContainer title={displayType}>
      {displayType === "Gross Margin" && (
        <MarginSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={historicalYears[0]}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      {displayType === "Sales" && (
        <SalesSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={historicalYears[0]}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      {displayType === "Transactions" && (
        <TransactionsSummary
          lastImported={
            store.sales_stats.last_transaction_date || store.updated_at
          }
          lastYearSales={historicalYears[0]}
          thisYearSales={thisYearSales}
          thisYearEstimates={store.sales_stats.current_year_estimates}
        />
      )}
      <ChartContainer
        width={500}
        height={300}
        series={[...barSeries, ...historicalSeries]}
        xAxis={[{ data: months, scaleType: "band" }]}
        yAxis={[
          {
            scaleType: "linear",
            min: 0,
            max: maxValue,
            tickNumber: 5,
            valueFormatter: (value) => {
              if (value === 0) return "0";

              return valueFormatter(value);
            },
          },
        ]}
        margin={{ left: 60, right: 0, top: 20, bottom: 70 }}
      >
        <BarPlot />
        <LinePlot />
        <MarkPlot />
        <LineHighlightPlot />
        <ChartsTooltip />
        <ChartsXAxis />
        <ChartsYAxis />
        <ChartsLegend
          position={{ vertical: "bottom", horizontal: "right" }}
          slotProps={{ legend: { padding: 0 } }}
        />
        <ChartsGrid
          horizontal
          classes={{
            horizontalLine: "opacity-50",
          }}
        />
      </ChartContainer>
    </WidgetContainer>
  );
};
