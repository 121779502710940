import { PrinterIcon } from "@heroicons/react/20/solid";
import { useIdle } from "@uidotdev/usehooks";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import type { FixedSizeList, ListOnScrollProps } from "react-window";
import { useDebouncedCallback } from "use-debounce";
import { FourOhFour } from "../../components/404";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons";
import { Checkbox, Input, MultiSelect, Select } from "../../components/forms";
import { Loading, LoadingOverlay } from "../../components/loading";
import { Modal } from "../../components/modal";
import { PrintContainer } from "../../components/print-container";
import {
  Actions,
  Brand,
  CaseQty,
  CurrentCost,
  DOH,
  Department,
  Description,
  FutureCost,
  ItemCode,
  LastCaseCost,
  LastCost,
  LineNumber,
  MarginDollars,
  MarginPercentage,
  OrderLot,
  Price,
  ProductName,
  QOH,
  QOHCases,
  SavingsDollars,
  SavingsIndicator,
  SavingsPercentage,
  Size,
  SizeOunces,
  StdQty,
  Type,
  getCasesSoldColumn,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { DisplayQuantityToggle } from "../../components/quantity-toggle";
import { SearchInput } from "../../components/search-input";
import { Toolbar } from "../../components/toolbar";
import {
  ColumnLabel,
  type ColumnLayout,
  type HeaderCellRenderer,
  type RowProperties,
  Cell as VirtualizedCell,
  VirtualizedTable,
} from "../../components/virtualized-table";
import type {
  ColumnDefinition,
  ColumnRenderer,
  CompareFunction,
} from "../../components/virtualized-table/column-definition";
import { multiSort } from "../../components/virtualized-table/column-sort";
import { AlertContext } from "../../contexts/alert-context";
import { StoreProductContext } from "../../contexts/store-product-context";
import { type Order, OrderStatus } from "../../data/order";
import {
  type OrderItem,
  OrderItemSchema,
  cases,
  totalCost,
  totalUnits,
  units,
} from "../../data/order-item";
import type { OrderOptions } from "../../data/order-options";
import { qohMinPercents } from "../../data/order-settings";
import {
  type Product,
  backgroundClass,
  caseDiscount,
  saleStat,
  volumeDiscount,
} from "../../data/product";
import type { Store } from "../../data/store";
import type { Vendor } from "../../data/vendor";
import {
  formatPercent,
  preciseCurrencyFormatter,
} from "../../helpers/format-currency";
import { useHijackSearch } from "../../helpers/hijack-search";
import { SendMethodLabels } from "../../helpers/send-method-labels";
import {
  type StringOption,
  stringOptionsToValues,
  useStringOptions,
} from "../../helpers/string-options";
import { useOrder, useOrders } from "../../hooks/orders";
import { useQueryFilterFunction } from "../../hooks/products";
import { useRowHighlighting } from "../../hooks/row-highlighting";
import { useSelectedStore } from "../../hooks/selected-store";
import { useVendor } from "../../hooks/vendors";
import { PurchaseOrder } from "./purchase-order";

type ProductOrderItem = {
  product: Product;
  suggested_cases?: number;
  cases?: number;
  suggested_units?: number;
  units?: number;
};

type Quantities = {
  suggested_units: number;
  suggested_cases: number;
};

const QOHMinPercent = [25, 50, 75, 100, 125, 150, 175, 200] as const;
type QOHMinPercent = (typeof QOHMinPercent)[number];

const SalesPeriod = [30, 60, 90, 180, 365] as const;
type SalesPeriod = (typeof SalesPeriod)[number];

function underThreshold(product: Product, options: OrderOptions) {
  return (
    product.inventory_qty <
    (saleStat(product, options.sales_period_days) *
      options.qoh_threshold_percent) /
      100
  );
}

function stockBelowOneItem(product: Product) {
  if (product.last_sold === null) {
    return false;
  }

  return (
    product.standard_qty > 1 && product.inventory_qty < product.standard_qty + 1
  );
}

function minQOH(product: Product) {
  return product.min_qoh && product.inventory_qty < product.min_qoh;
}

function antiRolloff(product: Product) {
  return product.inventory_qty <= 0 && saleStat(product, 90) > 0;
}

function getSuggestedQuantities(
  product: Product,
  options: OrderOptions,
): Quantities {
  if (product.disabled || product.auto_disabled) {
    return {
      suggested_units: 0,
      suggested_cases: 0,
    };
  }

  const sales = saleStat(product, options.sales_period_days);

  const sales_units =
    underThreshold(product, options) || stockBelowOneItem(product)
      ? Math.max(
          1,
          Math.round(
            (sales / options.sales_period_days) * 7 * options.reorder_weeks,
          ),
        )
      : 0;
  if (product.min_qoh === undefined) {
    throw "Product min_qoh is undefined";
  }
  const min_qoh_units = minQOH(product)
    ? product.min_qoh - product.inventory_qty
    : 0;
  const anti_rolloff_units = antiRolloff(product) ? 1 : 0;

  const suggested_total_units =
    Math.max(sales_units, min_qoh_units, anti_rolloff_units) *
    (1 + options.fudge_percent / 100);

  if (suggested_total_units === 0) {
    return {
      suggested_units: 0,
      suggested_cases: 0,
    };
  }

  if (!product.case_qty) {
    return {
      suggested_units: suggested_total_units,
      suggested_cases: 0,
    };
  }

  let suggested_cases = Math.floor(suggested_total_units / product.case_qty);
  let suggested_units = suggested_total_units % product.case_qty;

  if (suggested_cases <= 0) {
    // Minimum case order is 1. Round up 2/3 of a case or more of units to 1 case
    if (
      product.order_lot === "C" ||
      (product.order_lot === "U" && suggested_units > 0.66 * product.case_qty)
    ) {
      suggested_cases = 1;
      suggested_units = 0;
    }
  } else {
    // Round up 1/2 of a case or more of units to the next case
    if (suggested_units > 0.5 * product.case_qty) {
      suggested_cases += 1;
      suggested_units = 0;
    }
  }
  if (product.order_lot === "C") {
    suggested_units = 0;
  }

  return {
    suggested_units,
    suggested_cases,
  };
}

function createOrderItem(
  product: Product,
  items: OrderItem[],
  options: OrderOptions,
): ProductOrderItem {
  const existingItem = items.find((item) => item.product.id === product.id);
  return {
    ...existingItem,
    ...getSuggestedQuantities(product, options),
    product,
  };
}

function updateOrderQuanties(
  orderItems: OrderItem[],
  products: Product[],
  options: OrderOptions,
): ProductOrderItem[] {
  return products.map((product) =>
    createOrderItem(product, orderItems, options),
  );
}

function CountCell({
  orderItem,
  getCount,
  setCount,
  name,
  onKeyDown,
  onFocus,
}: {
  orderItem: ProductOrderItem;
  getCount: (orderItem: ProductOrderItem) => number;
  setCount: (orderItem: ProductOrderItem, count: number) => void;
  name: string;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const value = useMemo(() => getCount(orderItem), [orderItem, getCount]);

  return (
    <input
      type="number"
      min={0}
      max={999}
      step={1}
      value={value.toString()}
      onChange={(event) => setCount(orderItem, Number(event.target.value))}
      onWheel={(event) => event.currentTarget.blur()}
      className={`border border-gray-300 text-sm rounded-sm w-full ${
        value > 0 ? "text-[#5dc630] font-semibold" : ""
      }`}
      onClick={(event) => event.stopPropagation()}
      name={name}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
    />
  );
}

function countColumnName(field: "cases" | "units", rowIndex: number): string {
  return `${field}-${rowIndex}-input`;
}

function onCountKeyDown(
  event: React.KeyboardEvent<HTMLInputElement>,
  field: "cases" | "units",
  rowIndex: number,
) {
  if (event.key === "Enter") {
    const nextIndex = Math.max(event.shiftKey ? rowIndex - 1 : rowIndex + 1, 0);
    const nextCountName = countColumnName(field, nextIndex);
    const [nextInput] = document.getElementsByName(
      nextCountName,
    ) as NodeListOf<HTMLInputElement>;
    if (nextInput) {
      nextInput?.focus();
      nextInput?.select();
    }
  }
}

function makeCountColumn(
  setOrderItem: (orderItem: ProductOrderItem) => void,
  field: "cases" | "units",
): ColumnDefinition<ProductOrderItem> {
  function getCount(orderItem: ProductOrderItem): number {
    return field === "cases" ? cases(orderItem) : units(orderItem);
  }
  function setCount(orderItem: ProductOrderItem, count: number) {
    if (field === "cases") {
      // When updating case quantities, we assume the user does not want to order units & reset them to 0
      setOrderItem({ ...orderItem, cases: count, units: 0 });
    } else {
      setOrderItem({ ...orderItem, cases: orderItem.cases, units: count });
    }
  }
  const label = field === "cases" ? "Order Cases" : "Order Units";
  const orderItemToString = (orderItem: ProductOrderItem) =>
    `${getCount(orderItem)}`;
  return {
    label,
    minWidth: 80,
    toString: orderItemToString,
    canOverflow: false,
    renderer: (
      orderItem: ProductOrderItem,
      _stringRepresentation: string,
      rowIndex: number,
    ) => {
      return (
        <CountCell
          orderItem={orderItem}
          getCount={getCount}
          setCount={setCount}
          name={countColumnName(field, rowIndex)}
          onKeyDown={(event) => onCountKeyDown(event, field, rowIndex)}
          onFocus={(event) => event.currentTarget.select()}
        />
      );
    },
  };
}

const savingsColumn: ColumnDefinition<ProductOrderItem> = {
  label: "Buyup Savings",
  minWidth: 80,
  toString: (orderItem: ProductOrderItem) => {
    const swingSavings = caseDiscount(orderItem.product);
    const volumeSavings = volumeDiscount(
      orderItem.product,
      totalUnits(orderItem),
    );

    if (swingSavings === 0 && volumeSavings === 0) {
      return preciseCurrencyFormatter(0);
    }

    if (orderItem.product.pricing.volume_discounts) {
      return preciseCurrencyFormatter(volumeSavings * cases(orderItem));
    }

    return preciseCurrencyFormatter(swingSavings * cases(orderItem));
  },
  canOverflow: false,
};

const expectedSavings90d = (p: Product) => {
  if (p.case_qty === 0) {
    return 0;
  }

  const unitsSold90d = saleStat(p, 90);

  if (p.pricing.volume_discounts) {
    return (unitsSold90d / p.case_qty) * volumeDiscount(p, unitsSold90d);
  }

  return (unitsSold90d / p.case_qty) * caseDiscount(p);
};

const savingsPotential: ColumnDefinition<ProductOrderItem> = {
  label: "Savings Potential 90d",
  minWidth: 80,
  toString: (orderItem: ProductOrderItem) => {
    const potentialSavings = expectedSavings90d(orderItem.product);

    if (-0.01 < potentialSavings && potentialSavings < 0.01) {
      return preciseCurrencyFormatter(0);
    }

    return preciseCurrencyFormatter(potentialSavings);
  },
  compareFunction: (a: ProductOrderItem, b: ProductOrderItem) => {
    const aSavings = expectedSavings90d(a.product);
    const bSavings = expectedSavings90d(b.product);

    if (aSavings > bSavings) {
      return 1;
    }
    if (aSavings < bSavings) {
      return -1;
    }

    return 0;
  },
  canOverflow: false,
};

function volumeDiscountColumn(
  slot: number,
): ColumnDefinition<ProductOrderItem> {
  return {
    label: `Deal ${slot}`,
    minWidth: 80,
    toString: (_: ProductOrderItem) => "",
    renderer: (orderItem: ProductOrderItem) => {
      if (
        !orderItem.product.pricing.current_cost ||
        !orderItem.product.pricing.volume_discounts ||
        orderItem.product.pricing.volume_discounts.length <= slot - 1
      ) {
        return <></>;
      }

      const discount = orderItem.product.pricing.volume_discounts[slot - 1];

      return (
        <div className="grid grid-flow-rows justify-items-end align-items-center">
          <div className="p-px">
            {discount.min_units / orderItem.product.case_qty}C
          </div>
          <div className="p-px text-[#5dc630] font-semibold">
            {preciseCurrencyFormatter(
              orderItem.product.pricing.current_cost - discount.cost,
            )}
          </div>
          <div className="p-px">{preciseCurrencyFormatter(discount.cost)}</div>
        </div>
      );
    },
    canOverflow: false,
  };
}

function simplifyOrderItem(orderItem: ProductOrderItem): OrderItem {
  return OrderItemSchema.cast(orderItem, { stripUnknown: true });
}

function replaceItem(
  orderItems: ProductOrderItem[],
  newItem: ProductOrderItem,
) {
  return orderItems.map((item) =>
    item.product.id === newItem.product.id ? newItem : item,
  );
}

const REFRESH_DELAY_MS = 15 * 1000; // fifteen seconds
const IDLE_TIMEOUT_MS = 15 * 60 * 1000; // 15 minutes

type GetRemainingDirtyItemsProperties = {
  dirtyItems: ProductOrderItem[];
  updatedItems: OrderItem[];
};

// find locally dirty product order items that do not match the updated items. These items need to remain in the dirty list
// for future changes.
function getRemainingDirtyItems({
  dirtyItems,
  updatedItems,
}: GetRemainingDirtyItemsProperties): ProductOrderItem[] {
  return dirtyItems.filter((dirtyItem) => {
    const updatedItem = updatedItems.find(
      (updatedItem) => updatedItem.product.id === dirtyItem.product.id,
    );
    return (
      !updatedItem ||
      dirtyItem.cases !== updatedItem.cases ||
      dirtyItem.units !== updatedItem.units
    );
  });
}

function useOrderItems(properties: EditOrderPopulatedProperties) {
  const [order, setOrder] = useState(properties.order);
  const [options, setOptions] = useState<OrderOptions>(order.options);
  const products = properties.products;
  const store = properties.store;
  const idle = useIdle(IDLE_TIMEOUT_MS);

  const [dirtyItems, setDirtyItems] = useState<ProductOrderItem[]>([]);
  const { saveOrderItem, saveOrderOptions } = useOrders();
  const { addErrorAlert } = useContext(AlertContext);

  const [calculating, setCalculating] = useState(false);
  const navigate = useNavigate();
  const [orderItems, setOrderItems] = useState<ProductOrderItem[]>([]);

  const { deleteOrder, getOrder } = useOrders();

  const createOrderItems = useCallback(() => {
    // delay the calculation so calculating is set and displayed before this blocks UI update
    setTimeout(() => {
      const updatedOrderItems = updateOrderQuanties(
        order.items,
        products,
        options,
      );
      setOrderItems(updatedOrderItems);
      setCalculating(false);
    }, 300);
  }, [order, options, products]);

  // effect to update order items on first load
  // not bound to order, as we don't want this recalculated for order refresh
  useEffect(() => {
    if (orderItems.length > 0) return;

    setCalculating(true);

    createOrderItems();
  }, [orderItems, createOrderItems]);

  const debouncedCreateOrderItems = useDebouncedCallback(createOrderItems, 500);

  const saveOrderOptionsCallback = useCallback(() => {
    return saveOrderOptions(store.id, order.id, options).then(() => {
      setOrder((order) => ({ ...order, options }));
    });
  }, [order, options, store, saveOrderOptions]);

  const debouncedSaveOrderOptions = useDebouncedCallback(
    saveOrderOptionsCallback,
    3000,
  );

  // explicitly updating order when options change. If we try to do this with a useEffect,
  // then updating the order will trigger the useEffect again, causing an infinite loop
  const setOptionsCallback = useCallback(
    (options: OrderOptions) => {
      setOptions(options);

      debouncedCreateOrderItems();
      debouncedSaveOrderOptions();
    },
    [debouncedCreateOrderItems, debouncedSaveOrderOptions],
  );

  const deleteOrderCallback = useCallback(() => {
    if (!order) return;
    const response = confirm(
      "Are you sure you want to cancel this order? This cannot be undone.",
    );
    if (!response) {
      return;
    }

    deleteOrder(order).then((success) => {
      if (success) {
        navigate(`/stores/${order.store_id}/orders`);
      }
    });
  }, [deleteOrder, navigate, order]);

  const saveDirtyItems = useCallback(() => {
    const execute = async () => {
      const updatedItems: OrderItem[] = await Promise.all(
        dirtyItems.map((dirtyItem: ProductOrderItem) => {
          return saveOrderItem(
            store.id,
            order?.id,
            simplifyOrderItem(dirtyItem),
          );
        }),
      );
      // 1. get current list of dirtyItems (they may have changed since the above started)
      // 2. filter out any items that match the server response, those changes have been persisted
      setDirtyItems((dirtyItems) =>
        getRemainingDirtyItems({ dirtyItems, updatedItems }),
      );
    };
    execute();
  }, [store, order, dirtyItems, saveOrderItem]);

  const debouncedSaveDirtyItems = useDebouncedCallback(saveDirtyItems, 1000);

  const setOrderItem = useCallback(
    (orderItem: ProductOrderItem) => {
      setOrderItems((items) => replaceItem(items, orderItem));
      setDirtyItems((dirtyItems) => mergeDirtyItems(dirtyItems, orderItem));
      debouncedSaveDirtyItems();
    },
    [debouncedSaveDirtyItems],
  );

  const resetOrderCallback = useCallback(() => {
    if (!order) return;

    const userItems = orderItems.filter(itemHasUserCount);
    if (userItems.length === 0) {
      return;
    }

    const response = confirm(
      `This will reset changes you have made to ${userItems.length} item(s) this order. Are you sure you want to continue?`,
    );
    if (!response) {
      return;
    }

    for (const orderItem of userItems) {
      setOrderItem({
        ...orderItem,
        cases: undefined,
        units: undefined,
      });
    }
  }, [setOrderItem, order, orderItems]);

  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [lastRefreshedAgo, setLastRefreshedAgo] = useState("just now");

  const refreshOrder = useCallback(() => {
    // don't refresh this cycle if we have pending saves
    if (dirtyItems.length > 0) return;
    getOrder(store.id, order.id).then((order) => {
      if (order.status !== OrderStatus.Draft) {
        navigate(`/stores/${order.store_id}/orders/${order.id}/summary`);
        addErrorAlert("Order has been submitted and can no longer be edited.", {
          keepAfterRouteChange: true,
        });
      } else {
        // simply merge in the server-side order items with the client-side order items
        setLastRefreshed(new Date());
        setOrder(order);
        setOptions(order.options);
        setOrderItems((existingItems) => {
          // merge in any inbound changes
          const mergedItems = mergeOrderItems({
            existingItems,
            incomingItems: order.items,
          });
          // recalculate quantities to apply any updated options
          return updateOrderQuanties(mergedItems, products, order.options);
        });
      }
    });
  }, [
    dirtyItems,
    addErrorAlert,
    navigate,
    getOrder,
    order.id,
    store.id,
    products,
  ]);

  // dirtyItems changing causes refreshOrder to change, which then caused the idle useEffect to fire.
  // Storing the the callback inside a ref allows the useEffect to fire only for idle changes.
  const refreshOrderRef = useRef(refreshOrder);

  useEffect(() => {
    refreshOrderRef.current = refreshOrder;
  }, [refreshOrder]);

  useEffect(() => {
    // When transitioning from idle to active, refresh the order immediately
    if (!idle) {
      refreshOrderRef.current();
    }

    const timer = setInterval(() => {
      // note: this doesn't work for Safari as of 29-06-2024
      // https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
      if (navigator.onLine && !idle) {
        refreshOrderRef.current();
      }
    }, REFRESH_DELAY_MS);
    return () => clearInterval(timer);
  }, [idle]);

  useEffect(() => {
    const timer = setInterval(() => {
      setLastRefreshedAgo(
        `last synced ${formatDistanceToNowStrict(lastRefreshed, { addSuffix: true })}${idle ? " (paused)" : ""}`,
      );
    }, 1000);
    return () => clearInterval(timer);
  }, [lastRefreshed, idle]);

  return {
    order,
    orderItems,
    setOrderItem,
    calculating,
    resetOrder: resetOrderCallback,
    deleteOrder: deleteOrderCallback,
    options,
    setOptions: setOptionsCallback,
    lastRefreshedAgo,
  };
}

function mergeOrderItems({
  existingItems,
  incomingItems,
}: {
  existingItems: ProductOrderItem[];
  incomingItems: OrderItem[];
}): ProductOrderItem[] {
  const incomingItemsMap = new Map(
    incomingItems.map((item) => [item.product.id, item]),
  );

  return existingItems.map((existingItem) => {
    const incomingItem = incomingItemsMap.get(existingItem.product.id);
    if (!incomingItem) {
      return existingItem;
    }

    return {
      ...existingItem,
      cases: incomingItem.cases,
      units: incomingItem.units,
    };
  });
}

// add a new dirty item to the existing list, replacing any previous item for the same product
function mergeDirtyItems(
  dirtyItems: ProductOrderItem[],
  newItem: ProductOrderItem,
): ProductOrderItem[] {
  const newDirtyItems = dirtyItems.filter(
    (item) => item.product.id !== newItem.product.id,
  );
  return [...newDirtyItems, newItem];
}

function OptionWrapper({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`flex flex-row gap-2 items-center ${className || ""}`}>
      <div className="text-gray-600">{label}</div>
      {children}
    </div>
  );
}

function wrapCompareFunction(
  compareFunction: CompareFunction<Product> | undefined,
) {
  if (!compareFunction) {
    return undefined;
  }
  return (a: ProductOrderItem, b: ProductOrderItem) => {
    return compareFunction(a.product, b.product);
  };
}

function wrapRenderer(renderer: ColumnRenderer<Product> | undefined) {
  if (!renderer) {
    return undefined;
  }
  return (
    orderItem: ProductOrderItem,
    stringRepresentation: string,
    row: number,
  ) => {
    return renderer(orderItem.product, stringRepresentation, row);
  };
}

function wrapProductColumn(
  column: ColumnDefinition<Product>,
): ColumnDefinition<ProductOrderItem> {
  return {
    ...column,
    toString: (orderItem: ProductOrderItem) =>
      column.toString(orderItem.product),
    renderer: wrapRenderer(column.renderer),
    compareFunction: wrapCompareFunction(column.compareFunction),
  };
}

const extendedCostColumn: ColumnDefinition<ProductOrderItem> = {
  label: "Extended Cost",
  minWidth: 80,
  toString: (orderItem: ProductOrderItem) =>
    preciseCurrencyFormatter(totalCost(orderItem)),
  canOverflow: false,
};

const extendedBuyupCostColumn: ColumnDefinition<ProductOrderItem> = {
  label: "Extended Cost",
  minWidth: 80,
  toString: (orderItem: ProductOrderItem) =>
    preciseCurrencyFormatter(
      cases(orderItem) *
        orderItem.product.case_qty *
        orderItem.product.last_cost,
    ),
  canOverflow: false,
};

function useReorder(options: OrderOptions) {
  const reorderLabel = useMemo(() => {
    return (orderItem: ProductOrderItem) => {
      if (orderItem.product.disabled) {
        return "DISABLED";
      }
      if (orderItem.product.auto_disabled) {
        return "AUTO_DSBL";
      }

      if (
        underThreshold(orderItem.product, options) ||
        stockBelowOneItem(orderItem.product)
      ) {
        return "YES";
      }

      if (minQOH(orderItem.product)) {
        return "MIN_QOH";
      }

      if (antiRolloff(orderItem.product)) {
        return "ROLLOFF";
      }
      return "NO";
    };
  }, [options]);

  return useMemo(() => {
    const labelToSortOrder = {
      DISABLED: -1,
      AUTO_DSBL: -1,
      YES: 2,
      MIN_QOH: 2,
      ROLLOFF: 1,
      NO: 0,
    };

    return {
      label: "Reorder",
      minWidth: 70,
      toString: (orderItem: ProductOrderItem) => {
        if (itemHasUserCount(orderItem)) {
          return "USER";
        }

        return reorderLabel(orderItem);
      },
      renderer: (_: ProductOrderItem, label: string) => {
        return (
          <div
            className={`text-center ${
              label === "USER" ? "font-extrabold" : ""
            }`}
          >
            {label}
          </div>
        );
      },
      canOverflow: false,
      compareFunction: (a: ProductOrderItem, b: ProductOrderItem) => {
        const aOrder = labelToSortOrder[reorderLabel(a)];
        const bOrder = labelToSortOrder[reorderLabel(b)];

        if (aOrder === bOrder) {
          return 0;
        }

        return aOrder > bOrder ? 1 : -1;
      },
    };
  }, [reorderLabel]);
}

function useCompareFunction(
  reorderColumn: ColumnDefinition<ProductOrderItem>,
  isBuyup: boolean,
) {
  return useMemo(() => {
    if (isBuyup) {
      return multiSort([
        {
          column: savingsPotential,
          direction: "desc",
        },
      ]);
    }

    return multiSort([
      {
        column: reorderColumn,
        direction: "desc",
      },
      {
        column: wrapProductColumn(Department),
        direction: "asc",
      },
      {
        column: wrapProductColumn(SizeOunces),
        direction: "desc",
      },
      {
        column: wrapProductColumn(Type),
        direction: "asc",
      },
      {
        column: wrapProductColumn(ProductName),
        direction: "asc",
      },
    ]);
  }, [reorderColumn, isBuyup]);
}

type ColumnOptions = {
  store: Store;
  setOrderItem: (orderItem: ProductOrderItem) => void;
  reorderColumn: ColumnDefinition<ProductOrderItem>;
  options: OrderOptions;
  isBuyup: boolean;
  hasBuyupData: boolean;
  maxDeals: number;
  displayInCases: boolean;
};

function useColumns({
  store,
  setOrderItem,
  reorderColumn,
  options,
  isBuyup,
  hasBuyupData,
  maxDeals,
  displayInCases,
}: ColumnOptions): ColumnDefinition<ProductOrderItem>[] {
  return useMemo(() => {
    if (isBuyup) {
      if (store.billing_address.state === "OK") {
        return [
          LineNumber,
          wrapProductColumn(ItemCode),
          wrapProductColumn(ProductName),
          wrapProductColumn(Size),
          wrapProductColumn(CaseQty),
          wrapProductColumn(CurrentCost),
          wrapProductColumn(FutureCost),
          wrapProductColumn(SavingsDollars),
          wrapProductColumn(SavingsPercentage),
          wrapProductColumn(DOH),
          wrapProductColumn(QOHCases),
          wrapProductColumn(getCasesSoldColumn(30)),
          wrapProductColumn(getCasesSoldColumn(60)),
          wrapProductColumn(getCasesSoldColumn(90)),
          wrapProductColumn(getCasesSoldColumn(180)),
          wrapProductColumn(getCasesSoldColumn(365)),
          makeCountColumn(setOrderItem, "cases"),
          extendedBuyupCostColumn,
          savingsColumn,
          savingsPotential,
          wrapProductColumn(Actions),
        ];
      }

      if (store.billing_address.state === "NY") {
        const discountColumns = Array.from({ length: maxDeals }, (_, i) =>
          volumeDiscountColumn(i + 1),
        );

        return [
          LineNumber,
          wrapProductColumn(ItemCode),
          wrapProductColumn(ProductName),
          wrapProductColumn(Size),
          wrapProductColumn(CaseQty),
          wrapProductColumn(CurrentCost),
          ...discountColumns,
          wrapProductColumn(DOH),
          wrapProductColumn(QOHCases),
          wrapProductColumn(LastCaseCost),
          wrapProductColumn(getCasesSoldColumn(30)),
          wrapProductColumn(getCasesSoldColumn(60)),
          wrapProductColumn(getCasesSoldColumn(90)),
          wrapProductColumn(getCasesSoldColumn(180)),
          wrapProductColumn(getCasesSoldColumn(365)),
          makeCountColumn(setOrderItem, "cases"),
          extendedBuyupCostColumn,
          savingsColumn,
          savingsPotential,
          wrapProductColumn(Actions),
        ];
      }
    }

    const qohColum = displayInCases ? QOHCases : QOH;
    const salesColumn = displayInCases
      ? getCasesSoldColumn(options.sales_period_days)
      : getUnitsSoldColumn(options.sales_period_days);

    const columns = [
      wrapProductColumn(ItemCode),
      wrapProductColumn(ProductName),
      wrapProductColumn(Department),
      wrapProductColumn(Type),
      wrapProductColumn(Size),
      wrapProductColumn(CaseQty),
      wrapProductColumn(StdQty),
      wrapProductColumn(OrderLot),
      wrapProductColumn(DOH),
      wrapProductColumn(qohColum),
      wrapProductColumn(salesColumn),
      makeCountColumn(setOrderItem, "cases"),
      makeCountColumn(setOrderItem, "units"),
      wrapProductColumn(LastCost),
      extendedCostColumn,
      reorderColumn,
      wrapProductColumn(Price),
      wrapProductColumn(MarginPercentage),
      wrapProductColumn(MarginDollars),
    ];
    if (hasBuyupData) {
      columns.push(wrapProductColumn(SavingsIndicator));
    }
    columns.push(wrapProductColumn(Actions));

    return columns;
  }, [
    setOrderItem,
    options.sales_period_days,
    reorderColumn,
    isBuyup,
    hasBuyupData,
    maxDeals,
    store.billing_address.state,
    displayInCases,
  ]);
}

type ReviewOrderOptions = {
  orderItems: ProductOrderItem[];
  methods: string[];
  store: Store;
  vendor: Vendor;
  order: Order;
};

function itemHasCount(orderItem: ProductOrderItem): boolean {
  return cases(orderItem) > 0 || units(orderItem) > 0;
}

function itemHasUserCount(orderItem: ProductOrderItem): boolean {
  return orderItem.cases !== undefined || orderItem.units !== undefined;
}

function OrderHeader(
  items: ProductOrderItem[],
): HeaderCellRenderer<ProductOrderItem> {
  const caseCount = items.reduce((sum, item) => sum + cases(item), 0);
  const unitCount = items.reduce((sum, item) => sum + units(item), 0);
  const totalCost = items.reduce(
    (sum, item) =>
      sum +
      (cases(item) * item.product.case_qty + units(item)) *
        item.product.last_cost,
    0,
  );

  return (column: ColumnLayout<ProductOrderItem>): React.ReactNode => {
    switch (column.definition.label) {
      case "Order Cases":
        return <div className="content-center text-center">{caseCount}</div>;
      case "Order Units":
        return <div className="content-center text-center">{unitCount}</div>;
      case "Extended Cost":
        return (
          <div className="content-center text-center">
            {preciseCurrencyFormatter(totalCost)}
          </div>
        );
      default:
        return <></>;
    }
  };
}

function BuyupHeader(
  items: ProductOrderItem[],
): HeaderCellRenderer<ProductOrderItem> {
  const caseCount = items.reduce((sum, item) => sum + cases(item), 0);
  const totalCost = items.reduce(
    (sum, item) =>
      sum + cases(item) * item.product.case_qty * item.product.last_cost,
    0,
  );
  const savings = items.reduce((sum, item) => {
    if (item.product.pricing.volume_discounts) {
      return sum + cases(item) * volumeDiscount(item.product, totalUnits(item));
    }

    return sum + cases(item) * caseDiscount(item.product);
  }, 0);

  const savingsPercent = savings / (totalCost + savings);

  return (column: ColumnLayout<ProductOrderItem>): React.ReactNode => {
    switch (column.definition.label) {
      case "Order Cases":
        return <div className="content-center text-center">{caseCount}</div>;
      case "Extended Cost":
        return (
          <div className="content-center text-center">
            {preciseCurrencyFormatter(totalCost)}
          </div>
        );
      case "Buyup Savings":
        return (
          <div className="content-center text-center">
            {preciseCurrencyFormatter(savings)}
          </div>
        );
      case "Savings Potential 90d":
        return (
          <div
            className={`content-center text-center ${savingsPercent < 0 ? "text-red-600" : "text-green-600"}`}
          >
            {formatPercent(savingsPercent)}
          </div>
        );

      default:
        return <></>;
    }
  };
}

function RowRenderer({
  index,
  style,
  data,
}: RowProperties<ProductOrderItem>): React.ReactNode {
  const item: ProductOrderItem = useMemo(
    () => data.items[index],
    [data.items, index],
  );
  const { onRowClick, isHighlighted } = useRowHighlighting(item.product.id);

  const className = useMemo(() => {
    const baseClassName =
      "grid grid-flow-col divide-x divide-gray-200 hover:border-slate-500 hover:border-b-2 focus-within:border-slate-500 focus-within:border-b-2";

    const bgClassName = backgroundClass(index, item.product);
    const textClassName = itemHasCount(item) ? "" : "opacity-60";
    const borderClassName = isHighlighted
      ? "border-y-[3px] border-yellow-500"
      : "border-b-2 border-transparent";

    return `${baseClassName} ${bgClassName} ${borderClassName} ${textClassName}`;
  }, [index, item, isHighlighted]);
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: we don't want to highlight lines when people are using the keyboard
    <div style={style} key={index} className={className} onClick={onRowClick}>
      {data.layout.map((column) => (
        <VirtualizedCell
          column={column}
          item={item}
          key={column.definition.label}
          rowIndex={index}
        />
      ))}
    </div>
  );
}

function Divider() {
  return <hr className="border-t-2 border-gray-100 my-4" />;
}

function ReviewOrder({
  store,
  vendor,
  order,
  orderItems,
  methods,
}: ReviewOrderOptions) {
  const navigate = useNavigate();

  const [method, setMethod] = useState(
    vendor.order_settings.defaults.send_method || "email",
  );

  const compareFunction = multiSort([
    {
      column: wrapProductColumn(Department),
      direction: "desc",
    },
    {
      column: wrapProductColumn(Brand),
      direction: "asc",
    },
    {
      column: wrapProductColumn(Description),
      direction: "asc",
    },
    {
      column: wrapProductColumn(SizeOunces),
      direction: "asc",
    },
  ]);
  const selectedOrderItems = useMemo(() => {
    return orderItems.filter(itemHasCount).sort(compareFunction);
  }, [orderItems, compareFunction]);

  const { submitOrder } = useOrders();
  const [confirming, setConfirming] = useState(false);
  const submitOrderWithMethod = useCallback(
    (method: string) => {
      const updatedOrder = {
        ...order,
        items: selectedOrderItems.map(simplifyOrderItem),
      };

      submitOrder(store.id, updatedOrder, method).then((success) => {
        if (success) {
          navigate(`/stores/${store.id}/orders/${order.id}/summary`);
        }
      });
    },
    [order, selectedOrderItems, store, submitOrder, navigate],
  );

  const [printing, setPrinting] = useState(false);

  const [canSendOrder, disabledReason] = useMemo(() => {
    if (selectedOrderItems.length === 0) {
      return [false, "The order has no products"];
    }

    if (
      method === "email" &&
      vendor.order_settings.representatives.filter((r) => r.send_order)
        .length === 0
    ) {
      return [
        false,
        "There are no emails to send the order to. Please add a representative to your vendor",
      ];
    }

    return [true, ""];
  }, [selectedOrderItems, method, vendor]);

  return (
    <>
      {!confirming && (
        <div>
          <Toolbar>
            <OptionWrapper label="Send Method">
              <Select
                value={method}
                onChange={(event) => setMethod(event.target.value)}
                className="w-32"
              >
                {methods.map((method) => (
                  <option key={method} value={method}>
                    {SendMethodLabels[method] || method}
                  </option>
                ))}
              </Select>
            </OptionWrapper>
            <PrimaryButton
              onClick={() => setConfirming(true)}
              disabled={!canSendOrder}
              title={disabledReason !== "" ? disabledReason : undefined}
            >
              Submit Order
            </PrimaryButton>
            <PrimaryButton onClick={() => setPrinting(true)}>
              <PrinterIcon className="h-5 w-5" aria-hidden="true" />
            </PrimaryButton>
          </Toolbar>
          <Divider />
          <PurchaseOrder
            store={store}
            vendor={vendor}
            order={order}
            orderItems={selectedOrderItems}
          />
          {printing && (
            <PrintContainer onAfterPrint={() => setPrinting(false)}>
              <PurchaseOrder
                store={store}
                vendor={vendor}
                order={order}
                orderItems={selectedOrderItems}
              />
            </PrintContainer>
          )}
        </div>
      )}
      {confirming && (
        <div className="max-w-96">
          <div className="pb-8">
            <p className="pb-2">
              Are you sure you want to submit this{" "}
              <span className="font-semibold">{vendor.name}</span> order via{" "}
              <span className="font-semibold">
                {SendMethodLabels[method] || method}
              </span>
              ? You will not be able to modify the order after submission.
            </p>
            {method === "ftp" && (
              <p>
                We will electronically submit your order to the vendor and you
                will receive a confirmation email.
              </p>
            )}
            {method === "csvs" && (
              <p>
                You will receive an email with attached CSV files. You will need
                to submit those files the vendor's website to complete the
                order.
              </p>
            )}
          </div>
          <div className="flex justify-end gap-2">
            <SecondaryButton onClick={() => setConfirming(false)}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={() => submitOrderWithMethod(method)}>
              Send
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
}

const useSearchFocus = () => {
  const searchReference = useRef<HTMLInputElement>(null);

  useHijackSearch(() => {
    searchReference.current?.focus();
  });

  return searchReference;
};

function matchesSize(product: Product, sizes: string[]) {
  if (sizes.length === 0) {
    return true;
  }

  return sizes.includes(product.size);
}

function matchesIsBuyup(product: Product, isBuyup: boolean) {
  if (!isBuyup) {
    return true;
  }

  if (
    product.disabled ||
    product.auto_disabled ||
    saleStat(product, 90) === 0
  ) {
    return false;
  }

  return (
    caseDiscount(product) !== 0 ||
    volumeDiscount(product, saleStat(product, 90)) !== 0
  );
}

function useFilteredOrderItems({
  orderItems,
  query,
  sizes,
  isBuyup,
}: {
  orderItems: ProductOrderItem[];
  query: string;
  sizes: string[];
  isBuyup: boolean;
}) {
  const products = useMemo(
    () => orderItems.map((oi) => oi.product),
    [orderItems],
  );
  const matchesProduct = useQueryFilterFunction(query, products);

  return useMemo(() => {
    return orderItems.filter(
      (orderItem) =>
        matchesProduct(orderItem.product) &&
        matchesSize(orderItem.product, sizes) &&
        matchesIsBuyup(orderItem.product, isBuyup),
    );
  }, [orderItems, sizes, isBuyup, matchesProduct]);
}

const useProductSizes = (orderItems: ProductOrderItem[]) => {
  return useMemo(() => {
    const sizes = new Set<string>();
    const volumes = new Map<string, number>();
    for (const orderItem of orderItems) {
      sizes.add(orderItem.product.size);
      volumes.set(orderItem.product.size, orderItem.product.size_ounces);
    }
    return [...sizes].sort(
      (a, b) => (volumes.get(a) || 0) - (volumes.get(b) || 0),
    );
  }, [orderItems]);
};

export function EditOrder() {
  const { selectedStore } = useSelectedStore();
  const { orderId } = useParams();
  const { order, loading } = useOrder(selectedStore?.id, orderId);
  const { vendor, loading: vendorLoading } = useVendor(order?.vendor_id);
  const { products, loading: productsLoading } =
    useContext(StoreProductContext);
  const vendorProducts = useMemo(() => {
    if (!products || !vendor) {
      return [];
    }
    return products.filter((product) => product.vendor_id === vendor.vendor_id);
  }, [products, vendor]);

  if ((loading && !order) || vendorLoading || productsLoading) {
    return <Loading />;
  }

  if (!order || !vendor || !products || !selectedStore) {
    return <FourOhFour />;
  }

  return (
    <EditOrderPopulated
      order={order}
      vendor={vendor}
      products={vendorProducts}
      store={selectedStore}
    />
  );
}

type EditOrderPopulatedProperties = {
  order: Order;
  vendor: Vendor;
  products: Product[];
  store: Store;
};

function EditOrderPopulated(properties: EditOrderPopulatedProperties) {
  const [isBuyup, setIsBuyup] = useState(false);
  const navigate = useNavigate();
  const searchReference = useSearchFocus();

  const {
    order,
    orderItems,
    setOrderItem,
    calculating,
    resetOrder,
    deleteOrder,
    options,
    setOptions,
    lastRefreshedAgo,
  } = useOrderItems(properties);

  const reorderColumn = useReorder(options);
  const compareFunction = useCompareFunction(reorderColumn, isBuyup);

  const [query, setQuery] = useState("");
  const [sizes, setSizes] = useState([] as string[]);
  const sizeValues = useStringOptions(sizes);
  const productSizes = useProductSizes(orderItems);
  const productSizeOptions = useStringOptions(productSizes);

  const sortedOrderItems = useMemo(
    () => [...orderItems].sort(compareFunction),
    [orderItems, compareFunction],
  );

  const filteredOrderItems = useFilteredOrderItems({
    orderItems: sortedOrderItems,
    query,
    sizes,
    isBuyup,
  });

  const setSizesFilter = useCallback((sizeOptions: readonly StringOption[]) => {
    const sizes = stringOptionsToValues(sizeOptions);
    setSizes(sizes);
  }, []);

  const { saving: productSaving } = useContext(StoreProductContext);

  const hasBuyupData = useMemo(() => {
    return orderItems.some((orderItem) =>
      matchesIsBuyup(orderItem.product, true),
    );
  }, [orderItems]);

  const maxDeals = useMemo(() => {
    return Math.max(
      ...filteredOrderItems.map((orderItem) =>
        orderItem.product.pricing.volume_discounts
          ? orderItem.product.pricing.volume_discounts.length
          : 0,
      ),
    );
  }, [filteredOrderItems]);

  const [displayInCases, setDisplayInCases] = useState(false);

  const columns = useColumns({
    store: properties.store,
    setOrderItem,
    options,
    reorderColumn,
    isBuyup,
    hasBuyupData,
    maxDeals,
    displayInCases,
  });

  const [showSubmitOrder, setShowSubmitOrder] = useState(false);

  const toggleShowSubmitOrder = useCallback(() => {
    setShowSubmitOrder((show) => !show);
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [buyupScrollOffset, setBuyupScrollOffset] = useState(0);
  const onScroll = (properties: ListOnScrollProps) => {
    if (isBuyup) {
      setBuyupScrollOffset(properties.scrollOffset);
    } else {
      setScrollOffset(properties.scrollOffset);
    }
  };
  const listReference = useRef<FixedSizeList>(null);

  useEffect(() => {
    if (!order) return;
    if (order.store_id !== properties.store.id) {
      navigate(`/stores/${properties.store.id}/orders/`);
    }
  }, [navigate, properties.store, order]);

  const headerRenderers = useMemo(() => {
    if (isBuyup) {
      return [BuyupHeader(filteredOrderItems), ColumnLabel];
    }

    return [OrderHeader(filteredOrderItems), ColumnLabel];
  }, [isBuyup, filteredOrderItems]);

  // after the initial load, don't block the UI when loading
  if (calculating && orderItems.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <div className="grid grid-cols-3 items-center p-2">
        <div />
        <h1 className="text-2xl font-semibold justify-self-center">
          {properties.vendor.name} Order
        </h1>
        <span className="text-sm font-normal font-mono text-gray-300 justify-self-end">
          {lastRefreshedAgo}
        </span>
      </div>
      <Toolbar layout="justify-between">
        <div className="grid grid-flow-col gap-2">
          <OptionWrapper label="QOH Minimum">
            <Select
              value={options.qoh_threshold_percent}
              onChange={(event) =>
                setOptions({
                  ...options,
                  qoh_threshold_percent: Number(
                    event.target.value,
                  ) as QOHMinPercent,
                })
              }
            >
              {qohMinPercents.map((percent) => (
                <option key={percent} value={percent}>
                  {percent}%
                </option>
              ))}
            </Select>
          </OptionWrapper>
          <OptionWrapper label="Sales Period">
            <Select
              value={options.sales_period_days}
              onChange={(event) =>
                setOptions({
                  ...options,
                  sales_period_days: Number(event.target.value) as SalesPeriod,
                })
              }
            >
              {SalesPeriod.map((days) => (
                <option key={days} value={days}>
                  {days} days
                </option>
              ))}
            </Select>
          </OptionWrapper>
          <OptionWrapper label="Reorder Weeks Supply">
            <Input
              type="number"
              value={options.reorder_weeks}
              onChange={(event) =>
                setOptions({
                  ...options,
                  reorder_weeks: Number(event.target.value),
                })
              }
              className="w-16"
              min={1}
              max={12}
            />
          </OptionWrapper>
          <OptionWrapper label="Fudge Percent">
            <Input
              type="number"
              value={options.fudge_percent}
              onChange={(event) =>
                setOptions({
                  ...options,
                  fudge_percent: Number(event.target.value),
                })
              }
              className="w-16"
              min={0}
              max={100}
            />
          </OptionWrapper>
        </div>
        <div className="grid grid-flow-col gap-2 items-center">
          {hasBuyupData && (
            <OptionWrapper label="Buyup">
              <Checkbox
                invalid={false}
                checked={isBuyup}
                onChange={(event) => {
                  setIsBuyup(event.target.checked);
                  if (event.target.checked) {
                    listReference.current?.scrollTo(buyupScrollOffset);
                  } else {
                    listReference.current?.scrollTo(scrollOffset);
                  }
                }}
              />
            </OptionWrapper>
          )}
          <DisplayQuantityToggle
            displayInCases={displayInCases}
            setDisplayInCases={setDisplayInCases}
          />
          <OptionWrapper label="Size">
            <MultiSelect
              options={productSizeOptions}
              value={sizeValues}
              onChange={setSizesFilter}
              placeholder="Any"
              isMulti
            />
          </OptionWrapper>
          <SearchInput
            placeholder="Filter..."
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            inputRef={searchReference}
          />
          <DangerButton onClick={deleteOrder}>Cancel</DangerButton>
          <SecondaryButton onClick={resetOrder}>Reset</SecondaryButton>
          <PrimaryButton onClick={toggleShowSubmitOrder}>
            Purchase Order
          </PrimaryButton>
        </div>
      </Toolbar>
      <VirtualizedTable
        items={filteredOrderItems}
        columns={columns}
        rowRenderer={RowRenderer}
        headerRenderers={headerRenderers}
        rowHeight={
          isBuyup && properties.store.billing_address.state === "NY" ? 60 : 25
        }
        listReference={listReference}
        onScroll={onScroll}
      />
      {showSubmitOrder && (
        <Modal title="Purchase Order" visible onClose={toggleShowSubmitOrder}>
          <ReviewOrder
            store={properties.store}
            vendor={properties.vendor}
            order={order}
            orderItems={sortedOrderItems}
            methods={options.send_methods}
          />
        </Modal>
      )}
      {productSaving && <LoadingOverlay />}
    </>
  );
}
