import { datadogRum } from "@datadog/browser-rum";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router";
import App from "./app";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import "./index.css";

if (import.meta.env.MODE === "production") {
  datadogRum.init({
    applicationId: "48c1ed9c-5980-4a00-a4d0-6009d196df4b",
    clientToken: "pub71cdba21338436a7f988f00d06968290",
    site: "datadoghq.com",
    service: "app.liquorstore.tech",
    env: "prod",
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
}

const domNode = document.querySelector("#root") as Element;
const root = ReactDOM.createRoot(domNode);
root.render(
  <Router>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </Router>,
);

function removeServiceWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          console.log("Unregistering service worker: ", registration);
          registration.unregister();
          console.log("Refreshing page");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("Service Worker unregistration failed: ", error);
      });
  }
}

removeServiceWorker();
